import z from 'zod'

import { ArticleSchema } from './article'

// list of all possible generic aritcle numbers
const GENERIC_ARTICLE_NUMBERS_LIST = [
  '50000074',
  '20000141',
  '78610400',
  '10000561',
  '90000562',
]

export function isGenericArticle(articleNumbers: string[] | undefined) {
  return articleNumbers?.some((articleNumber) =>
    GENERIC_ARTICLE_NUMBERS_LIST.includes(articleNumber),
  )
}

export const ReasonDiscountSchema = z.object({
  id: z.number().nullable().optional(),
  countryCode: z.string().nullable().optional(),
  languageCode: z.string().nullable().optional(),
  reasonForDiscount: z.string(),
  translation: z.string().nullable().optional(),
})

export const UnitItemTranslationSchema = z.object({
  id: z.number().optional(),
  item_id: z.number().optional(),
  title: z.string().optional(),
  description: z.string().optional(),
  languageCode: z.string().optional(),
  by_default: z.boolean().optional(),
  location: z.string().optional(),
  additionalInfo: z.string().optional(),
  reasonDiscount: z.string().optional(),
  language_id: z.number().optional(),
})
export const UnitListItemTranslationSchema = z.object({
  title: z.string().optional(),
  description: z.string().optional(),
  languageCode: z.string(),
  location: z.string().nullable().optional(),
  language_id: z.number().optional(),
})

export const ReservedBySchema = z.object({
  id: z.number(),
  email: z.string().nullable().optional(),
  lang_code: z.string(),
  name: z.string(),
})

export const UnitItemStateSchema = z.enum([
  'NEW',
  'PUBLISHED',
  'UNPUBLISHED',
  'RESERVED',
  'SOLD',
  'REMOVED',
  'COLLECTED',
])
export const UnitItemState = UnitItemStateSchema.enum

export const UnitItemImageSchema = z.object({
  id: z.number(),
  url: z.string(),
  display_order: z.number(),
})

export const DefectImageNoteSchema = z.object({
  id: z.number().optional(),
  upload_id: z.number().optional(),
  languageCode: z.string(),
  notes: z.string().max(86),
  by_default: z.boolean(),
})

export const UnitItemDefectImageSchema = z.object({
  url: z.string(),
  id: z.number().nullable().optional(),
  name: z.string(),
  notes: z.string().nullable().optional(),
  uploadedNotes: z.array(DefectImageNoteSchema).nullable(),
})

export const ReservationSchema = z.object({
  customerEmail: z.string().nullable().optional(),
  customerName: z.string().nullable().optional(),
  reservationId: z.string().nullable().optional(),
  reservedOn: z.string().nullable().optional(),
})

export const UnitListItemSchema = z.object({
  createdOn: z.string().nullable().optional(),
  currencyCode: z.string().nullable().optional(),
  heroImage: z.string().optional(),
  itemId: z.number(),
  itemTranslations: z.array(UnitListItemTranslationSchema),
  price: z.number(),
  reservation: ReservationSchema.nullable().optional(),
  sekundId: z.string(),
  state: UnitItemStateSchema,
  updatedOn: z.number().optional(),
  articleNumbers: z.array(z.string()),
})

export const UnitItemSchema = z.object({
  id: z.number(),
  lang_code: z.string(),
  country_code: z.string(),
  images: z.array(UnitItemImageSchema),
  published: z.boolean(),
  unitId: z.string(),
  title: z.string().optional(),
  description: z.string().optional(),
  price: z.number(),
  qty: z.number().optional(),
  state: UnitItemStateSchema,
  tagId: z.string(),
  reserved_by: ReservedBySchema.nullable().optional(),
  reservationId: z.string().nullable().optional(),
  articles: z.array(ArticleSchema).optional(),
  uploads: z.array(UnitItemDefectImageSchema).optional(),
  itemTranslations: z.array(UnitItemTranslationSchema),
  public_images: z.string().nullable().optional(),
  last_update: z.number().optional(),
  reserved_at: z.number().nullable().optional(),
  categories: z.array(z.number()),
  search_category: z.array(z.string().optional()).nullable(),
  priority: z.number().int().min(0), // TODO: Check if min(0) can be changed into positive()
  reserve_expiration: z.number().nullable().optional(),
  email: z.string().nullable().optional(),
  createdBy: z.string().nullable().optional(),
  createdAt: z.string().nullable().optional(),
  multiple: z.boolean().optional(),
  productCondition: z.object({ code: z.string() }).nullable().optional(),
  isInBox: z.boolean().nullable().optional(),
  location: z.string().nullable().optional(),
})
export type ReasonDiscount = z.infer<typeof ReasonDiscountSchema>
export type UnitItemDefectImage = z.infer<typeof UnitItemDefectImageSchema>
export type UnitItemImage = z.infer<typeof UnitItemImageSchema>
export type UnitItemTranslation = z.infer<typeof UnitItemTranslationSchema>
export type UnitItem = z.infer<typeof UnitItemSchema>
export type UnitListItem = z.infer<typeof UnitListItemSchema>
